import { mobileCheck } from '../utils/mobile-or-desktop'
const device = mobileCheck() ? "Mobile" : "Desktop";

const purchaseBannerElement = document.getElementById("recent-investments");
if (purchaseBannerElement) {
  purchaseBannerElement.addEventListener("click", () => {
    purchaseBannerElement.style.display = "none";
    if (device === "Mobile") {
      localStorage.setItem("timeStampPurchaseBannerHided", new Date().getTime());
    }
  });

  if (device === "Desktop") {
    purchaseBannerElement.style = "display: block;"
  } else {
    const onPropertiesForSalePage = location.pathname.startsWith("/properties_for_sale/");
    const currentTime = new Date().getTime();
    const timeStampPurchaseBannerHided = localStorage.getItem("timeStampPurchaseBannerHided");
    const shouldToggleBannerBackOn = (currentTime - timeStampPurchaseBannerHided) > 24 * 60 * 60 * 1000;
    if (onPropertiesForSalePage && (!timeStampPurchaseBannerHided || shouldToggleBannerBackOn)) purchaseBannerElement.style = "display: block;"
  }
}
